import { Factory } from '../interface/factory';
import { Scope } from '../enum/scope';
import { CountryCode } from '../enum/country-code';

export enum RoleType {
  Global = 'Global',
  Country = 'Country',
  Organization = 'Organization',
}

export abstract class Role {
  type: RoleType;
  scopes: Scope;

  constructor(json: any, type: RoleType) {
    this.type = type;
    this.scopes = json.scopes as Scope;
  }

  static getFactory(): Factory<Role> {
    return new (class implements Factory<Role> {
      make(json: any): Role {
        switch (json.type) {
          case RoleType.Global:
            return new GlobalRole(json);
          case RoleType.Country:
            return new CountryRole(json);
          case RoleType.Organization:
            return new OrganizationRole(json);
          default:
            throw new Error('Unrecognized Role type (' + json.type + ').');
        }
      }

      getTableName(): string {
        throw new Error('getTableName() should never be called on a Roles. Roles are not stored in IndexDB.');
        return 'roles';
      }
    })();
  }
}

export class GlobalRole extends Role {
  constructor(json: any) {
    super(json, RoleType.Global);
  }
}

export class CountryRole extends Role {
  code: CountryCode;
  constructor(json: any) {
    super(json, RoleType.Country);
    this.code = json.code as CountryCode;
  }
}

export class OrganizationRole extends Role {
  id: string;
  constructor(json: any) {
    super(json, RoleType.Organization);
    this.id = json.id;
  }
}
