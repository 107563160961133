export enum CardBrand {
  Unknown = 'Unknown',
  VISA = 'VISA',
  MasterCard = 'MasterCard',
  Maestro = 'Maestro',
  Electron = 'Electron',
  DebitMasterCard = 'DebitMasterCard',
  VisaDebit = 'VisaDebit',
  Laser = 'Laser',
  Solo = 'Solo',
  AMEX = 'AMEX',
  Diners = 'Diners',
  UkMaestro = 'UkMaestro',
  JCB = 'JCB',
  UkashNeo = 'UkashNeo',
  Discover = 'Discover',
}
