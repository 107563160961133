export class AwaitingSubject<T> {
  private subscribers: { next: (value: T | SubscriberSentinel) => Promise<void> }[];

  constructor() {
    this.subscribers = [];
  }

  subscribe(subscriber: { next: (value: T | SubscriberSentinel) => Promise<void> }): Subscription<T> {
    this.subscribers.push(subscriber);
    return new Subscription(subscriber, this);
  }

  async unsubscribe(subscriber: { next: (value: T | SubscriberSentinel) => Promise<void> }): Promise<void> {
    for (let i = 0; i < this.subscribers.length; i++) {
      if (this.subscribers[i].next == subscriber.next) {
        // Is this the same function?
        this.subscribers.splice(i, 1); // Remove.
        return;
      }
    }
  }

  async next(value: T | SubscriberSentinel): Promise<void> {
    for (let i = 0; i < this.subscribers.length; i++) {
      await this.subscribers[i].next(value);
    }
  }

  get subCount(): number {
    return this.subscribers.length;
  }
}

export class SubscriberSentinel {}

export class Subscription<T> {
  constructor(
    private subscriber: { next: (value: T | SubscriberSentinel) => Promise<void> },
    private subject: AwaitingSubject<T>
  ) {}

  async unsubscribe() {
    await this.subject.unsubscribe(this.subscriber);
  }
}
