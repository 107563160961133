import { FaultCode } from '../enum/fault-code';

export class Fault {
  code: FaultCode;
  text?: string;
  set?: Fault[];
  aligns?: boolean;
  file?: string;
  line?: number;

  constructor(json: any) {
    this.code = json.code as FaultCode;
    this.text = json.text;
    this.aligns = json.aligns;
    this.file = json.file;
    this.line = json.line;

    if (json.code == FaultCode.Set && json.set) {
      const set: Fault[] = json.set.map((json: any) => new Fault(json));
      // If only one in set, elevate.
      if (set.length == 1) {
        this.code = set[0].code;
        this.text = set[0].text;
        this.aligns = set[0].aligns;
        this.file = set[0].file;
        this.line = set[0].line;
        this.set = undefined;
      } else {
        this.set = set;
      }
    } else {
      this.set = undefined;
    }
  }
}
