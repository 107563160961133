import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtmService {
  /**
   * Map containing UTM parameters.
   * Example: key='utm_source', value='newsletter'
   * @type {Map<string, string>}
   * @private
   */
  private utmMap: Map<string, string> = new Map();

  constructor() {}

  /**
   * Fetches the current URL, deciphers UTM parameters, and puts them into the map.
   * Example URL: https://example.com/home?utm_source=newsletter&utm_medium=email&utm_campaign=spring_sale
   * @returns {Promise<void>}
   */
  async fetchUtmParameters(): Promise<void> {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.forEach((value, key) => {
      if (Object.values(UTMKeys).includes(key as UTMKeys)) {
        this.utmMap.set(key, value);
      }
    });
  }

  /**
   * Returns the values of the UTM map as an array.
   * @returns {string[]} An array of UTM parameter values.
   */
  public getUtmValues(): string[] {
    return Array.from(this.utmMap.values());
  }

  /**
   * Gets a specific value from the UTM map via a key.
   * @param {UTMKeys} key - The key of the UTM parameter.
   * @returns {string | undefined} The value associated with the specified key, or undefined if the key doesn't exist.
   * Logs an error to the console if the key does not exist.
   */
  public getUtmValue(key: UTMKeys): string | undefined {
    if (this.utmMap.has(key)) {
      return this.utmMap.get(key);
    } else {
      // console.error(`UTM parameter ${key} does not exist`);
      return undefined;
    }
  }
}

/**
 * Enum for UTM parameter keys.
 * @readonly
 * @enum {string}
 */
export enum UTMKeys {
  UTM_SOURCE = 'utm_source',
  UTM_MEDIUM = 'utm_medium',
  UTM_CAMPAIGN = 'utm_campaign',
  UTM_ID = 'utm_id',
  UTM_TERM = 'utm_term',
  UTM_CONTENT = 'utm_content',
}
