import { Injectable } from '@angular/core';
import * as LogRocket from 'logrocket';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LogrocketService {
//   user?: LogRocketUser;

//   updateUser(user: LogRocketUser) {
//     this.user = user;
//     this.identify();
//   }

//   identify() {
//     if (this.user && this.user.phone && environment.production) {
//       LogRocket.identify(this.user.phone, this.user);
//     }
//   }
// }

// export class LogRocketUser {
//   [propName: string]: string | number | boolean;
//   phone: string;
//   id: string;
//   name: string;
//   email: string;
//   signOut: boolean;
//   addUser: boolean;
//   setActiveUser: boolean;

//   constructor(json: any) {
//     this.phone = json.phone ?? '';
//     this.id = json.id ?? '';
//     this.name = json.name ?? '';
//     this.email = json.email ?? '';
//     this.signOut = json.signOut ?? false;
//     this.addUser = json.addUser ?? false;
//     this.setActiveUser = json.setActiveUser ?? false;
//   }
}
