// https://en.wikipedia.org/wiki/IETF_language_tag
// https://www.venea.net/web/culture_code

export enum LanguageCode {
  Swedish = 'sv', // Sweden sv-SE
  Norwegian = 'no', // Norway no-NO
  NorwegianBokmal = 'nb-no', // Norway nb-NO
  NorwegianNynorsk = 'nn-no', // Norway nb-NO
  Finnish = 'fi', // Finland fi-FI
  Danish = 'da', // Denmark da-DK
  English = 'en', // English en-US
}
