import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FaultCode, I18nString, LanguageCode } from 'src/app/models';
import { Utilities } from 'src/app/models/class/utilities';
import { RegionService } from 'src/app/services/region/region.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  private error$ = new BehaviorSubject(new Filter(false, ''));
  error = this.error$.asObservable();
  language: LanguageCode;
  Utilities = Utilities;

  constructor(private regionService: RegionService) {
    this.language = this.regionService.getClientLanguage();
  }

 /**
 * Sets an error message and code, and notifies subscribers through the error$ Subject.
 *
 * @param {string | I18nString[]} [message] - The error message or an array of I18nString instances.
 * @param {FaultCode} [code] - The fault code associated with the error.
 * @returns {void}
 * @throws {Error} Throws an error if an invalid I18nString array is provided.
 * @example
 * // Setting a simple error message
 * setError('Invalid input.');
 *
 * // Setting an error message with an associated fault code
 * setError('Network error', FaultCode.NetworkError);
 *
 * // Setting an error message using I18nString for localization
 * setError([new I18nString('error.network'), new I18nString('error.retry')]);
 *
 * // Setting a default error message in different languages
 * setError(); // Uses default messages based on the current language
 * setError(undefined, FaultCode.GenericError); // Uses default messages with a fault code
 */
  setError(message?: string | I18nString[], code?: FaultCode, title?: string, subTitle?: string): void {
    // Handle I18nString instances and default error messages
    if(Utilities.isArrayI18n(message)){
      message = Utilities.parseI18n(message, this.language);
    }

    const i18n: I18nString[] = [];

    i18n.push(
      {
        language: LanguageCode.Swedish,
        value: 'Inget felmeddelande tillhandahölls.',
      },
      {
        language: LanguageCode.Norwegian,
        value: 'Ingen feilmelding ble gitt.',
      },
    );

    // Ensure a default error message if 'message' is undefined
    const errorMessage = message ?? Utilities.parseI18n(i18n, this.language);

    // Create a Filter object and notify subscribers
    const filter = new Filter(true, errorMessage, code, title, subTitle);
    this.error$.next(filter);
  }

  /**
   * Clears the error message and notifies subscribers by setting the error status to false.
   *
   * This function removes any previously set error message, allowing the application to indicate
   *
   * that there are no current errors to be displayed.
   *
   * @returns {void}
   */
  clearError(): void {
    const filter = new Filter(false, '');
    this.error$.next(filter);
  }
}

class Filter {
  active: boolean;
  message: string;
  code?: FaultCode;
  title?: string;
  subTitle?: string;

  constructor(active: boolean, message: string, code?: FaultCode, title?: string, subTitle?: string) {
    this.active = active;
    this.message = message;
    this.code = code;
    this.title = title;
    this.subTitle = subTitle;
  }
}
