import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CountryCode, PaymentType, Period } from 'src/app/models';
import { PriceService } from '../price/price.service';
import { RegionService } from '../region/region.service';

@Injectable({
  providedIn: 'root',
})
export class FormServiceService {
  signUpForm: FormGroup;
  paymentForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private priceService: PriceService,
    private regionService: RegionService,
  ) {
    this.signUpForm = this.fb.group({
      dob: new FormControl('', this.dobRegionValidation(this.regionService.getClientRegion())),
      firstName: new FormControl('', Validators.required),
      middleName: new FormControl(''),
      preferredName: new FormControl(''),
      lastName: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      postcode: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(5)]),
      city: new FormControl('', Validators.required),
      phoneNumber: new FormControl('', Validators.required),
      phoneVerificationCode: new FormControl('', [Validators.required, Validators.minLength(6)]),
      countryCode: new FormControl(
        { value: this.regionService.getClientRegion(), disabled: false },
        Validators.required,
      ),
      email: new FormControl('', [Validators.required, Validators.email]),
      emailVerificationCode: new FormControl(''),
      country: new FormControl({ value: this.regionService.getClientRegion(), disabled: false }, Validators.required),
    });

    this.paymentForm = this.fb.group({
      paymentFrequency: new FormControl('', Validators.required),
      paymentMethod: new FormControl(PaymentType.Card),
      cardPayment: new FormControl(''),
      discountCode: new FormControl(''),
      acceptTermsAndConditions: new FormControl(false, Validators.requiredTrue),
    });
    this.paymentForm.controls['paymentFrequency'].valueChanges.subscribe((value) => {
      this.updatePaymentFrequency(value);
    });

  }

  async updatePaymentFrequency(paymentFrequency: Period) {
    localStorage.setItem('paymentFrequency', paymentFrequency);
    let discount = await this.priceService.getActiveDiscount();
    let template = await this.priceService.getActiveTemplate();
    if (discount && template) {
      this.priceService.setPriceFromActiveDiscountAndDiscountTemplate(paymentFrequency);
    } else if (template) {
      this.priceService.setPriceFromActiveTemplate(paymentFrequency);
    } else {
      return;
    }
  }

  dobRegionValidation(region: CountryCode) {
    switch (region) {
      case CountryCode.SE:
        return [Validators.required, Validators.minLength(12), Validators.maxLength(12)];
      case CountryCode.NO:
        return [Validators.required, Validators.minLength(8), Validators.maxLength(8)];
      default:
        return Validators.required;
    }
  }

  initilizePaymentFrequency(): Period | undefined {
    let paymentFrequency = localStorage.getItem('paymentFrequency');
    if (paymentFrequency) {
      this.paymentForm.controls['paymentFrequency'].setValue(paymentFrequency);
      return paymentFrequency as Period;
    }
    return undefined;
  }

  setSignUpForm(form: FormGroup) {
    this.signUpForm.patchValue(form);
  }

  isInputValid(controlName: string): string {
    const control = this.signUpForm.get(controlName);
    if (!control) {
      return '';
    }

    if (control.invalid && control.dirty) {
      return 'border-font_error_bright';
    }

    if (control.invalid && control.touched) {
      return 'border-font_error_bright';
    }
    if (control.valid) {
      return 'border-font_success';
    }
    return '';
  }

  isFormValid(): boolean {
    return this.signUpForm.valid;
  }

  async storeSignUpFormToSessionStorage() {
    sessionStorage.setItem('signUpForm', JSON.stringify(this.signUpForm.value));
  }

  async getSignUpFormFromSessionStorage(control?: string) {
    let form = sessionStorage.getItem('signUpForm');
    if (form && control) {
      const formControl = JSON.parse(form)[control];
      return formControl;
    } else if (form) {
      return JSON.parse(form);
    } else {
      return '';
    }
  }

  getSignUpForm(): FormGroup {
    return this.signUpForm;
  }

  getForm() {
    return this.paymentForm;
  }

  getUser() {
    const personalDetails = this.paymentForm.get('personalDetails')?.value;
    const firstName = personalDetails.firstName;
    const lastName = personalDetails.lastName;
    return `${firstName} ${lastName}`;
  }

  getTrimmedFormValue(control: AbstractControl | null): string | undefined {
    return control && control.value && typeof control.value === 'string' ? control.value.trim() : undefined;
  }
}
