import { Factory } from '../interface/factory';
import { I18nString } from '../class/i18nstring';

export enum MediaType {
  Image = 'Image',
  Video = 'Video',
}

export abstract class Media {
  type: MediaType;
  filename: string;
  descriptions: I18nString[];

  constructor(json: any, type: MediaType) {
    this.type = type;
    this.filename = json.filename;
    this.descriptions = json.descriptions ? json.descriptions.map((js: any) => new I18nString(js)) : [];
  }

  static getFactory(): Factory<Media> {
    return new (class implements Factory<Media> {
      make(json: any): Media {
        switch (json.type) {
          case MediaType.Image:
            return new ImageMedia(json);
          case MediaType.Video:
            return new VideoMedia(json);
          default:
            throw new Error('Unrecognized Media type (' + json.type + ').');
        }
      }
      getTableName(): string {
        throw new Error('getTableName() should never be called on a Media. Media is not stored in IndexDB.');
        return 'media';
      }
    })();
  }
}

export class ImageMedia extends Media {
  constructor(json: any) {
    super(json, MediaType.Image);
  }
}

export class VideoMedia extends Media {
  constructor(json: any) {
    super(json, MediaType.Video);
  }
}
