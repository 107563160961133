import { Deletable } from '../interface/deletable';
import { Identifiable } from '../interface/identifiable';
import { Factory } from '../interface/factory';
import { I18nString } from '../class/i18nstring';
import { HasVehicleBrandId } from '../interface/has-vehicle-brand-id';
import { ObjectType } from './object';

export enum VehicleModelType {
  PB = 'PB', // Personbil, Car
  HB = 'HB', // Husbil, Camper
  LLB = 'LLB', // Lätt Lastbil, Light Truck
  TL = 'TL', // Tung Lastbil, Heavy Truck
  TB = 'TB', // Tung Buss, Heavy Bus
  HV = 'HV', // Husvagn, Caravan
  LSL = 'LSL', // Släpfordon, Light Trailer
  MC = 'MC', // Motorcykel, Motorbike
  MB = 'MB', // Mopedbil, Moped Car
  TSL = 'TSL', // Tungt släp, Heavy Trailer
}

export class VehicleModel implements Deletable, Identifiable, HasVehicleBrandId {
  id: string;
  deleted: boolean;
  deletedDbFlag: 0 | 1; // NOTE: Only used for indexing in browser DB.

  type: ObjectType;
  vehicleBrandId: string;

  names: I18nString[];

  created: Date;
  modified: Date;

  constructor(json: any) {
    this.id = json.id;
    this.deleted = json.deleted ? Boolean(json.deleted) : false;
    this.deletedDbFlag = this.deleted ? 1 : 0;

    this.type = json.type as ObjectType;
    this.vehicleBrandId = json.vehicleBrandId;

    this.names = json.names ? json.names.map((json: any) => new I18nString(json)) : [];

    this.created = new Date(json.created);
    this.modified = new Date(json.modified);
  }

  public static getFactory(): Factory<VehicleModel> {
    return new (class implements Factory<VehicleModel> {
      make(json: any): VehicleModel {
        return new VehicleModel(json);
      }
      getTableName(): string {
        return 'vehicle_models';
      }
    })();
  }

  static getUrl(brandId: string, modelId?: string): string;
  static getUrl(brandId: string, modelId: string): string {
    return '/vehicle_brands/' + brandId + '/vehicle_models' + (modelId ? '/' + modelId : '');
  }
}
