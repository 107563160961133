import { CountryCode } from '../enum/country-code';
import { Currency } from '../enum/currency';
import { Factory } from '../interface/factory';
import { Identifiable } from '../interface/identifiable';
import { MothershipData } from './mothership-data';
import { Owner } from './owner';
import { PaymentServiceProvider } from './payment-method';
import { Product } from './product/product';
import { ObjectProductTemplate, ProductTemplate, UserProductTemplate } from './product/product-template';

export interface PaymentUi {
  actual: Payment;
  card?: CardPayment;
  invoice?: InvoicePayment;
  creditNote?: CreditNotePayment;
  avtaleGiro?: AvtaleGiroPayment;
  template: UserProductTemplate | ObjectProductTemplate
}

export enum PaymentType {
  Card = 'Card',
  Invoice = 'Invoice',
  AvtaleGiro = 'AvtaleGiro',
  CreditNote = 'CreditNote',
}

export class Payment implements Identifiable {
  id: string;
  type: PaymentType;

  aliasId: number;
  voucherNumber?: number;

  // NOTE: Lower index is higher in the hierarchy. Denormalized from product.
  issuingOrganizationIds: string[];

  countryCode: CountryCode;
  owner: Owner;

  amount: number; // NOTE: After discount.
  currency: Currency;

  created: Date;
  modified: Date;

  constructor(json: any, type: PaymentType) {
    this.id = json.id;
    this.type = type;

    this.aliasId = json?.aliasId !== undefined ? Number(json.aliasId) : 0;
    this.voucherNumber = json?.voucherNumber !== undefined ? Number(json.voucherNumber) : undefined;

    this.issuingOrganizationIds = json.issuingOrganizationIds ? json.issuingOrganizationIds : [];

    this.countryCode = json.countryCode as CountryCode;

    const ownerFactory = Owner.getFactory();
    this.owner = ownerFactory.make(json.owner);

    this.amount = Number(json.amount);
    this.currency = json.currency as Currency;

    this.created = new Date(json.created);
    this.modified = new Date(json.modified);
  }

  public static getFactory(): Factory<Payment> {
    return new (class implements Factory<Payment> {
      make(json: any): Payment {
        switch (json.type) {
          case PaymentType.Card:
            return new CardPayment(json);
          case PaymentType.Invoice:
            return new InvoicePayment(json);
          case PaymentType.CreditNote:
            return new CreditNotePayment(json);
          case PaymentType.AvtaleGiro:
            return new AvtaleGiroPayment(json);
          default:
            throw new Error('Unrecognized Payment type (' + json.type + ').');
        }
      }

      getTableName(): string {
        return 'payments';
      }
    })();
  }

  static getUrl(paymentId?: string): string;
  static getUrl(paymentId: string): string {
    return '/payments' + (paymentId ? '/' + paymentId : '');
  }
}

export enum CardTransactionStatus {
  Authorized = 'Authorized', // Reserverad
  Captured = 'Captured', // Betald
}

export class CardPayment extends Payment implements Identifiable {
  // NOTE: Id from psp.
  transactionStatus: CardTransactionStatus;
  provider: PaymentServiceProvider;
  paymentMethodId?: string;
  verificationUrl?: string;
  captured?: Date;
  mothershipData?: MothershipData;
  reference: string;

  constructor(json: any) {
    super(json, PaymentType.Card);
    this.issuingOrganizationIds = json.issuingOrganizationIds ? json.issuingOrganizationIds : [];
    this.transactionStatus = json.transactionStatus as CardTransactionStatus;
    this.provider = json.provider as PaymentServiceProvider;
    this.paymentMethodId = json.paymentMethodId ? json.paymentMethodId : undefined;
    this.verificationUrl = json.verificationUrl ? json.verificationUrl : undefined;
    this.captured = json.captured ? new Date(json.captured) : undefined;
    this.mothershipData = json.mothershipData ? json.mothershipData : undefined;
    this.reference = json.reference;
  }
}

export class InvoicePayment extends Payment implements Identifiable {
  kid: string;

  partlyPaid?: number;
  invoiceFee?: number;

  dueDate: Date;
  paidDate?: Date;

  mothershipData?: MothershipData;

  constructor(json: any) {
    super(json, PaymentType.Invoice);

    this.kid = json.kid;

    this.partlyPaid = json.partlyPaid !== undefined ? Number(json.partlyPaid) : undefined;
    this.invoiceFee = json.invoiceFee !== undefined ? Number(json.invoiceFee) : undefined;

    this.dueDate = new Date(json.dueDate);
    this.paidDate = json.paidDate ? new Date(json.paidDate) : undefined;

    this.mothershipData = json.mothershipData ? json.mothershipData : undefined;
  }
}

export class AvtaleGiroPayment extends Payment {
  kid: string;

  dueDate: Date;
  paidDate?: Date;

  constructor(json: any) {
    super(json, PaymentType.AvtaleGiro);

    this.kid = json.kid;

    this.dueDate = new Date(json.dueDate);
    this.paidDate = json.paidDate ? new Date(json.paidDate) : undefined;
  }
}

export class CreditNotePayment extends Payment {
  kid: string;

  invoiceFee?: number;

  // NOTE: Due date for credit note is the same as created.

  mothershipData?: MothershipData;

  constructor(json: any) {
    super(json, PaymentType.CreditNote);

    this.kid = json.kid;

    this.invoiceFee = json.invoiceFee !== undefined ? Number(json.invoiceFee) : undefined;

    this.mothershipData = json.mothershipData ? json.mothershipData : undefined;
  }
}

export enum FailedAttemptType {
  Card = 'Card',
}

export class FailedAttempt {
  type: FailedAttemptType;

  constructor(json: any, type: FailedAttemptType) {
    this.type = type;
  }

  public static getFactory(): Factory<FailedAttempt> {
    return new (class implements Factory<FailedAttempt> {
      make(json: any): FailedAttempt {
        switch (json.type) {
          case FailedAttemptType.Card:
            return new CardFailedAttempt(json);
          default:
            throw new Error('Unrecognized FailedAttempt type (' + json.type + ').');
        }
      }

      getTableName(): string {
        throw new Error(
          'getTableName() should never be called on a FailedAttempt. FailedAttempts are not stored in IndexedDB.'
        );
        return 'failed_attempts';
      }
    })();
  }
}

export class CardFailedAttempt extends FailedAttempt {
  paymentError: PaymentError;
  amount: number; // NOTE: After discount.
  paymentMethodId: string;
  discountId?: string;
  verificationUrl?: string;
  from: Date;
  to: Date;
  at: Date; // Time of payment attempt.

  constructor(json: any) {
    super(json, FailedAttemptType.Card);
    this.paymentError = new PaymentError(json.paymentError);
    this.amount = Number(json.amount);
    this.paymentMethodId = json.paymentMethodId;
    this.discountId = json.discountId ? json.discountId : undefined;
    this.verificationUrl = json.verificationUrl ? json.verificationUrl : undefined;
    this.from = new Date(json.from);
    this.to = new Date(json.to);
    this.at = new Date(json.at);
  }
}

export enum PaymentErrorType {
  Unspecified = 'Unspecified',
}

export class PaymentError {
  type: PaymentErrorType;
  reason: string;
  constructor(reason: string) {
    this.type = PaymentErrorType.Unspecified;
    this.reason = reason;
  }
}
