import { Injectable } from '@angular/core';
import { RegionService } from '../region/region.service';
import { CountryCode } from 'src/app/models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CookiebotService {
  cookiebotIdMap: Map<CountryCode, string> = new Map<CountryCode, string>();

  constructor(private regionService: RegionService) {
    this.cookiebotIdMap.set(CountryCode.NO, '01e64dac-a45b-401b-88ba-7060a209d19c');
    this.cookiebotIdMap.set(CountryCode.SE, 'c7421d01-bd44-4ce9-9ed8-a7832f20659b');
  }

  public async getCookiebotId(): Promise<string | undefined> {
    return this.cookiebotIdMap.get(this.regionService.getClientRegion());
  }
}
