import { Currency } from '../enum/currency';

import { Identifiable } from '../interface/identifiable';
import { Deletable } from '../interface/deletable';
import { Factory } from '../interface/factory';
import { CountryCode } from '../enum/country-code';
import { I18nString } from './i18nstring';
import { HasCountryCode } from '../interface/has-country-code';

import { Period } from '../enum/period';
import { DiscountEligability } from './discount-eligability';
import { Modifiable } from '../interface/modifiable';

export enum DiscountType {
  PercentageVoucher = 'PercentageVoucher',
  SinglePeriodPercentageVoucher = 'SinglePeriodPercentageVoucher',
  SinglePeriodPriceCampaign = 'SinglePeriodPriceCampaign',
}

export class Discount {
  type: DiscountType;

  constructor(json: any, type: DiscountType) {
    this.type = type;
  }

  public static getFactory(): Factory<Discount> {
    return new (class implements Factory<Discount> {
      make(json: any): Discount {
        switch (json.type) {
          case DiscountType.PercentageVoucher:
            return new DiscountPercentageVoucher(json);
          case DiscountType.SinglePeriodPercentageVoucher:
            return new DiscountSinglePeriodPercentageVoucher(json);
          case DiscountType.SinglePeriodPriceCampaign:
            return new DiscountSinglePeriodPriceCampaign(json);
          default:
            throw new Error(
              'Unrecognized Discount type (' + json.type + ').'
            );
        }
      }

      getTableName(): string {
        return '';
      }
    })();
  }

  static getUrl(): string {
    return '';
  }
}

// Claimable, redeemable and applies for all payments until expiry.
 export class DiscountPercentageVoucher
  extends Discount
  implements Identifiable, HasCountryCode
{
  id: string;
  templateId?: string;
  code: string; // NOTE: When a template is deleted, the code is set to template id.
  countryCode: CountryCode;
  owner: Owner;
  eligability?: DiscountEligability[];
  percent: string;
  titles?: I18nString[];
  texts?: I18nString[];
  expiry?: Date;
  created: Date;
  modified: Date;

  constructor(json: any) {
    super(json, DiscountType.PercentageVoucher);
    this.id = json.id;
    this.templateId = json.templateId ?? undefined;
    this.code = json.code;
    this.countryCode = json.countryCode as CountryCode;
    const of: Factory<Owner> = Owner.getFactory();
    this.owner = of.make(json.owner);
    const ef: Factory<DiscountEligability> = DiscountEligability.getFactory();
    this.eligability = json.eligability
      ? json.eligability.map((js: any) => ef.make(js))
      : undefined;
    this.percent = json.percent;
    this.titles = json.titles
      ? json.titles.map((js: any) => new I18nString(js))
      : undefined;
    this.texts = json.texts
      ? json.texts.map((js: any) => new I18nString(js))
      : undefined;
    this.expiry = json.expiry ? new Date(json.expiry) : undefined;
    this.created = new Date(json.created);
    this.modified = new Date(json.modified);
  }
}

// Claimable and redeemable until expiry. Applies for payments in one period only.
export class DiscountSinglePeriodPercentageVoucher
  extends Discount
  implements Identifiable, HasCountryCode
{
  id: string;
  templateId?: string;
  code: string; // NOTE: When a template is deleted, the code is set to template id.
  countryCode: CountryCode;
  owner: Owner;
  eligability?: DiscountEligability[];
  percent: string;
  titles?: I18nString[];
  texts?: I18nString[];
  period: Period;
  expiry?: Date;
  created: Date;
  modified: Date;

  constructor(json: any) {
    super(json, DiscountType.SinglePeriodPercentageVoucher);
    this.id = json.id;
    this.templateId = json.templateId ?? undefined;
    this.code = json.code;
    this.countryCode = json.countryCode as CountryCode;
    const of: Factory<Owner> = Owner.getFactory();
    this.owner = of.make(json.owner);
    const ef: Factory<DiscountEligability> = DiscountEligability.getFactory();
    this.eligability = json.eligability
      ? json.eligability.map((js: any) => ef.make(js))
      : undefined;
    this.percent =json.percent;
    this.titles = json.titles
      ? json.titles.map((js: any) => new I18nString(js))
      : undefined;
    this.texts = json.texts
      ? json.texts.map((js: any) => new I18nString(js))
      : undefined;
    this.period = json.period as Period;
    this.expiry = json.expiry ? new Date(json.expiry) : undefined;
    this.created = new Date(json.created);
    this.modified = new Date(json.modified);
  }
}

// For use in product template costs.
// NOTE: Redeemable until expiry.
export class DiscountSinglePeriodPriceCampaign extends Discount implements Identifiable, Modifiable {
  id: string;
  countryCode: CountryCode;
  owner: Owner;
  productId: string;
  start: Date;
  end: Date;
  created: Date;
  modified: Date;
  
  constructor(json: any) {
    super(json, DiscountType.SinglePeriodPriceCampaign);
    this.id = json.id;
    this.countryCode = json.countryCode as CountryCode;
    const of: Factory<Owner> = Owner.getFactory();
    this.owner = of.make(json.owner);
    this.productId = json.productId ?? undefined;
    this.start = new Date(json.start);
    this.end = new Date(json.end);
    this.created = new Date(json.created);
    this.modified = new Date(json.modified);
    
  }
}

enum OwnerType {
  User = 'User',
  Organization = 'Organization',
}

class Owner {
  type: OwnerType;

  constructor(json: any, type: OwnerType) {
    this.type = type;
  }

  public static getFactory(): Factory<Owner> {
    return new (class implements Factory<Owner> {
      make(json: any): Owner {
        switch (json.type) {
          case OwnerType.User:
            return new User(json);
          case OwnerType.Organization:
            return new Organization(json);
          default:
            throw new Error('Unrecognized Owner type (' + json.type + ').');
        }
      }

      getTableName(): string {
        return '';
      }
    })();
  }

  static getUrl(): string {
    return '';
  }
}

class User extends Owner implements Identifiable {
  id: string;
  constructor(json: any) {
    super(json, OwnerType.User);
    this.id = json.id;
  }
}

class Organization extends Owner implements Identifiable {
  id: string;
  constructor(json: any) {
    super(json, OwnerType.Organization);
    this.id = json.id;
  }
}
