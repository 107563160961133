export class NewUserResponse {
  accessToken: string;
  refreshToken: string;
  userId: string;
  constructor(json: any) {
    this.accessToken = json.accessToken;
    this.refreshToken = json.refreshToken;
    this.userId = json.userId;
  }
}
