import { Identifiable } from '../interface/identifiable';
import { Deletable } from '../interface/deletable';
import { HasCountryCode } from '../interface/has-country-code';
import { CountryCode } from '../enum/country-code';
import { Factory } from '../interface/factory';
import { Owner } from './owner';

export enum VikingObjectType {
  Vehicle = 'Vehicle',
  Interest = 'Interest',
}

export class VikingObject implements Identifiable, Deletable, HasCountryCode {
  id: string; // CountryCode + regNr
  modelId: string;
  brandId: string;

  type: VikingObjectType;
  regNr: string;
  countryCode: CountryCode;

  deleted: boolean;
  created: Date;
  modified: Date;

  constructor(json: any, type: VikingObjectType) {
    this.id = json.id; // CountryCode + regNr
    this.modelId = json.modelId;
    this.brandId = json.brandId;

    this.type = type;
    this.regNr = json.regNr;
    this.countryCode = json.countryCode as CountryCode;

    this.deleted = json.deleted;
    this.created = new Date(json.created);
    this.modified = new Date(json.modified);
  }

  public static getFactory(): Factory<VikingObject> {
    return new (class implements Factory<VikingObject> {
      make(json: any) {
        switch (json.type) {
          case VikingObjectType.Vehicle:
            return new Vehicle(json);
          case VikingObjectType.Interest:
            return new Interest(json);
          default:
            throw new Error('Unrecognized object type (' + json.type + ').');
        }
      }

      getTableName(): string {
        return 'objects';
      }
    })();
  }

  getName(): string {
    return this.id.slice(2);
  }

  static getUrl(countryCode: CountryCode, id?: string): string;
  static getUrl(countryCode: CountryCode, id: string): string {
    return '/countries/' + countryCode + '/objects' + (id ? '/' + id : '');
  }
}

export class Vehicle extends VikingObject {
  products: VehicleProduct[];

  constructor(json: any) {
    super(json, VikingObjectType.Vehicle);
    this.products = json.products?.map((p: any) => new VehicleProduct(p)) ?? [];
  }

  public static extractCountryFromId(vehicleId: string): CountryCode {
    const pattern = new RegExp(`^(${Object.values(CountryCode).join('|')})`);

    const match = vehicleId.match(pattern);
    if (match && match[0] in CountryCode) {
      return CountryCode[match[0] as keyof typeof CountryCode];
    }

    throw new Error('Given vehicleId ' + vehicleId + ' does not start with a known country code.');
  }
}

export class Interest extends VikingObject {
  owner: Owner;
  objectId: string;

  constructor(json: any) {
    super(json, VikingObjectType.Interest);
    this.owner = json.owner;
    this.objectId = json.objectId;
  }
}

export class VehicleProduct {
  id: string;
  countryCode: CountryCode;

  constructor(json: any) {
    this.id = json.id;
    this.countryCode = json.countryCode;
  }
}
