import { Factory } from '../interface/factory';

export enum PhoneStatus {
  Unverified = 'Unverified',
  PendingVerification = 'PendingVerification',
  Verified = 'Verified',
}

export class ExtraPhoneVerification {
  phoneNumber: string;
  phoneVerificationCode: string;

  constructor(phoneNumber: string, phoneVerificationCode: string) {
    this.phoneNumber = phoneNumber;
    this.phoneVerificationCode = phoneVerificationCode;
  }
}

export abstract class Phone {
  status: PhoneStatus;
  number: string;

  constructor(json: any, status: PhoneStatus) {
    this.status = status;
    this.number = json.number;
  }

  static getFactory(): Factory<Phone> {
    return new (class implements Factory<Phone> {
      make(json: any): Phone {
        switch (json.status) {
          case PhoneStatus.Unverified:
            return new UnverifiedPhone(json);
          case PhoneStatus.PendingVerification:
            return new PendingPhone(json);
          case PhoneStatus.Verified:
            return new VerifiedPhone(json);
          default:
            throw new Error('Unrecognized Phone status (' + json.status + ').');
        }
      }

      getTableName(): string {
        throw new Error('getTableName() should never be called on a Phone. Phones are not stored in IndexDB.');
        return 'phones';
      }
    })();
  }
}

export class UnverifiedPhone extends Phone {
  constructor(json: any) {
    super(json, PhoneStatus.Unverified);
  }
}

export class PendingPhone extends Phone {
  code: string; // Encrypted.
  constructor(json: any) {
    super(json, PhoneStatus.PendingVerification);
    this.code = json.code;
  }
}

export class VerifiedPhone extends Phone {
  constructor(json: any) {
    super(json, PhoneStatus.Verified);
  }
}
