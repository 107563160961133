import { Factory } from '../interface/factory';

export enum EmailStatus {
  Unverified = 'Unverified',
  PendingVerification = 'PendingVerification',
  Verified = 'Verified',
}

export abstract class Email {
  status: EmailStatus;
  address: string;

  constructor(json: any, status: EmailStatus) {
    this.status = status;
    this.address = json.address;
  }

  static getFactory(): Factory<Email> {
    return new (class implements Factory<Email> {
      make(json: any): Email {
        switch (json.status) {
          case EmailStatus.Unverified:
            return new UnverifiedEmail(json);
          case EmailStatus.PendingVerification:
            return new PendingEmail(json);
          case EmailStatus.Verified:
            return new VerifiedEmail(json);
          default:
            throw new Error('Unrecognized Email status (' + json.status + ').');
        }
      }

      getTableName(): string {
        throw new Error('getTableName() should never be called on a Email. Emails are not stored in IndexDB.');
        return 'emails';
      }
    })();
  }
}

export class UnverifiedEmail extends Email {
  constructor(json: any) {
    super(json, EmailStatus.Unverified);
  }
}

export class PendingEmail extends Email {
  code: string; // Encrypted.
  constructor(json: any) {
    super(json, EmailStatus.PendingVerification);
    this.code = json.code;
  }
}

export class VerifiedEmail extends Email {
  constructor(json: any) {
    super(json, EmailStatus.Verified);
  }
}
