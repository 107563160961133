import { Deletable } from '../interface/deletable';
import { Factory } from '../interface/factory';
import { OwnerType } from './owner';

export enum DiscountEligabilityType {
  Product = 'Product',
  Organization = 'Organization',
  Staff = 'Staff',
}

export class DiscountEligability {
  type: DiscountEligabilityType;

  constructor(json: any, type: DiscountEligabilityType) {
    this.type = type;
  }

  public static getFactory(): Factory<DiscountEligability> {
    return new (class implements Factory<DiscountEligability> {
      make(json: any): DiscountEligability {
        switch (json.type) {
          case DiscountEligabilityType.Product:
            return new ProductEligability(json);
          case DiscountEligabilityType.Organization:
            return new OrganizationEligability(json);
          case DiscountEligabilityType.Staff:
            return new StaffEligability(json);
          default:
            throw new Error(
              'Unrecognized DiscountEligability type (' + json.type + ').'
            );
        }
      }

      getTableName(): string {
        throw new Error(
          'Discount eligabilities are never stored in IndexedDB.'
        );
      }
    })();
  }
}

// Discount is only valid for a specific product.
export class ProductEligability
  extends DiscountEligability
  implements Deletable
{
  deleted: boolean;
  productId: string;
  organizationId: string; // Denormalized for search.
  coversOwnerTypes: OwnerType[];
  constructor(json: any) {
    super(json, DiscountEligabilityType.Product);
    this.deleted = json.deleted;
    this.productId = json.productId;
    this.organizationId = json.organizationId;
    this.coversOwnerTypes = json.coversOwnerTypes
      ? json.coversOwnerTypes.map((t: any) => t as OwnerType)
      : [];
  }
}

// Discount is only valid for a specific organization.
export class OrganizationEligability
  extends DiscountEligability
  implements Deletable
{
  deleted: boolean;
  organizationId: string;
  coversOwnerTypes: OwnerType[];
  constructor(json: any) {
    super(json, DiscountEligabilityType.Organization);
    this.deleted = json.deleted;
    this.organizationId = json.organizationId;
    this.coversOwnerTypes = json.coversOwnerTypes
      ? json.coversOwnerTypes.map((t: any) => t as OwnerType)
      : [];
  }
}

// Only staff can apply this discount. This can only be used in conjunction with other
// eligability types.
export class StaffEligability extends DiscountEligability {
  constructor(json: any) {
    super(json, DiscountEligabilityType.Staff);
  }
}
