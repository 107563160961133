import { Deletable } from '../interface/deletable';
import { HasCountryCode } from '../interface/has-country-code';
import { Identifiable } from '../interface/identifiable';
import { I18nString } from '../class/i18nstring';
import { Factory } from '../interface/factory';
import { CountryCode } from '../enum/country-code';

export class Organization implements Deletable, Identifiable, HasCountryCode {
  id: string;
  deleted: boolean;

  countryCode: CountryCode;

  parentIds: string[]; // NOTE: Lower index is higher in the hierarchy.
  names: I18nString[];
  logo?: string;

  created: Date;
  modified: Date;

  constructor(json: any) {
    this.id = json.id;
    this.deleted = json.deleted ? Boolean(json.deleted) : false;

    this.countryCode = json.countryCode as CountryCode;

    this.parentIds = json.parentIds ? json.parentIds : [];

    this.names = json.names ? json.names.map((json: any) => new I18nString(json)) : [];
    this.logo = json.logo ? json.sublocality : undefined;

    this.created = new Date(json.created);
    this.modified = new Date(json.modified);
  }

  static getFactory(): Factory<Organization> {
    return new (class implements Factory<Organization> {
      make(json: any): Organization {
        return new Organization(json);
      }
      getTableName(): string {
        return 'organizations';
      }
    })();
  }

  static getUrl(orgId?: string): string;
  static getUrl(orgId: string): string {
    return '/organizations' + (orgId ? '/' + orgId : '');
  }

  getIconFileName(): string {
    return this.countryCode.toLocaleLowerCase() + '.svg';
  }
}
