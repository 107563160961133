<div class="page-wrapper">
  <section class="error-wrapper">
    <h2 *ngIf="!title">Something went wrong!</h2>
    <h3 *ngIf="!subTitle">
      Is the error recurring? <br />
      Copy the message and contact support!
    </h3>

    <h2 *ngIf="title">{{ title }}</h2>
    <h3 *ngIf="subTitle">{{ subTitle }}</h3>
    

    <p *ngIf="code">Error code: {{ code }}</p>
    <p>{{ message }}</p>

    <div>
      <button (click)="copyToClipboard()" [disabled]="copied">
        <span *ngIf="!copied">Copy the error message</span>
        <span *ngIf="copied">Copied to clipboard</span>
      </button>
      <button (click)="clearError()" class="bg-background_interactive" >Close</button>
    </div>
  </section>
</div>
