import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ErrorService } from './error.service';
import { FaultCode } from 'src/app/models';


@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit, OnDestroy {
  private $?: Subscription;

  active: boolean;
  message: string;
  code?: FaultCode;
  copied: boolean;

  title?: string;
  subTitle?: string;

  constructor(
    private errorService: ErrorService,
  ) {
    this.active = false;
    this.message = '';
    this.copied = false;
  }

  ngOnInit(): void {
    this.$ = this.errorService.error.subscribe((e) => {
      this.active = e.active;
      this.message = e.message;
      this.code = e.code;
      this.copied = false;
      this.title = e.title;
      this.subTitle = e.subTitle;
    });
  }

  ngOnDestroy(): void {
    this.errorService.clearError();
    if (this.$) {
      this.$?.unsubscribe();
    }
  }

  clearError() {
    this.errorService.clearError();
  }

  copyToClipboard() {
    const code = this.code ?? 'No code provided.';
    const message = this.message ?? 'No message provided.';

    const text = 'Code: ' + code + ' System message: ' + message;
    try {
      navigator.clipboard.writeText(text);
      this.copied = true;
    } catch (x) {
      alert('Could not copy to clipboard' + x);
    }
  }
}
