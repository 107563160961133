import { environment } from '../../../environments/environment';
import { Deletable } from '../interface/deletable';
import { Identifiable } from '../interface/identifiable';
import { HasCountryCode } from '../interface/has-country-code';
import { Factory } from '../interface/factory';
import { I18nString } from '../class/i18nstring';
import { PublishStatus } from '../enum/publish-status';
import { CountryCode } from '../enum/country-code';
import { Media } from './media';

export enum StoryActionType {
  Url = 'Url',
  MailTo = 'MailTo',
}

export abstract class StoryAction {
  type: StoryActionType;
  titles: I18nString[];

  constructor(json: any, type: StoryActionType) {
    this.type = type;
    this.titles = json.titles ? json.titles.map((js: any) => new I18nString(js)) : [];
  }

  static getFactory(): Factory<StoryAction> {
    return new (class implements Factory<StoryAction> {
      make(json: any): StoryAction {
        switch (json.type) {
          case StoryActionType.Url:
            return new UrlAction(json);
          case StoryActionType.MailTo:
            return new MailToAction(json);
          default:
            throw new Error('Unrecognized StoryAction type (' + json.type + ').');
        }
      }

      getTableName(): string {
        throw new Error(
          'getTableName() should never be called on a StoryAction. StoryActions are not stored in IndexDB.'
        );
        return 'stories';
      }
    })();
  }
}

export class UrlAction extends StoryAction {
  url: string;
  constructor(json: any) {
    super(json, StoryActionType.Url);
    this.url = json.url;
  }
}

export class MailToAction extends StoryAction {
  email: string;
  subjects: I18nString[];
  bodies: I18nString[];
  constructor(json: any) {
    super(json, StoryActionType.MailTo);
    this.email = json.email;
    this.subjects = json.subjects ? json.subjects.map((js: any) => new I18nString(js)) : [];
    this.bodies = json.bodies ? json.bodies.map((js: any) => new I18nString(js)) : [];
  }
}

export enum StoryType {
  Tip = 'Tip',
  News = 'News',
  Offer = 'Offer',
}

export class Story implements Deletable, Identifiable, HasCountryCode {
  id: string;
  deleted: boolean;
  deletedDbFlag: 0 | 1; // NOTE: Only used for indexing in browser DB.

  type: StoryType;

  countryCode: CountryCode;

  status: PublishStatus;
  titles: I18nString[];
  subtitles: I18nString[];
  texts: I18nString[];

  media: Media[];
  backgroundColor: string; // Example: #7d7d7d

  start: Date;
  end?: Date;

  action?: StoryAction;

  created: Date;
  modified: Date;

  constructor(json: any, type: StoryType) {
    this.id = json.id;
    this.deleted = json.deleted ? Boolean(json.deleted) : false;
    this.deletedDbFlag = json.deleted ? 1 : 0;

    this.type = type;

    this.countryCode = json.countryCode as CountryCode;

    this.status = json.status as PublishStatus;
    this.titles = json.titles ? json.titles.map((json: any) => new I18nString(json)) : [];
    this.subtitles = json.subtitles ? json.subtitles.map((json: any) => new I18nString(json)) : [];
    this.texts = json.texts ? json.texts.map((json: any) => new I18nString(json)) : [];

    const mediaFactory: Factory<Media> = Media.getFactory();
    this.media = json.media ? json.media.map((mjson: any) => mediaFactory.make(mjson)) : [];

    this.backgroundColor = json.backgroundColor;

    this.start = new Date(json.start);
    this.end = json.end ? new Date(json.end) : undefined;

    this.action = json.action ? StoryAction.getFactory().make(json.action) : undefined;

    this.created = new Date(json.created);
    this.modified = new Date(json.modified);
  }

  public static getFactory(): Factory<Story> {
    return new (class implements Factory<Story> {
      make(json: any): Story {
        switch (json.type) {
          case StoryType.News:
            return new News(json);
          case StoryType.Offer:
            return new Offer(json);
          case StoryType.Tip:
            return new Tip(json);
          default:
            throw new Error('Unrecognized Story type (' + json.type + ').');
        }
      }
      getTableName(): string {
        return 'stories';
      }
    })();
  }

  static getUrl(countryCode: CountryCode, storyId?: string): string;
  static getUrl(countryCode: CountryCode, storyId: string): string {
    return '/countries/' + countryCode + '/stories' + (storyId ? '/' + storyId : '');
  }

  getImageUrl(index: number = 0): string | undefined {
    return this.media[index] ? environment.blobUrl + '/files/' + this.media[index].filename : undefined;
  }
}

export class News extends Story {
  constructor(json: any) {
    super(json, StoryType.News);
  }
}

export class Offer extends Story {
  constructor(json: any) {
    super(json, StoryType.Offer);
  }
}

export class Tip extends Story {
  constructor(json: any) {
    super(json, StoryType.Tip);
  }
}
