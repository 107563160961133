import { Identifiable } from '../interface/identifiable';
import { Factory } from '../interface/factory';

export enum OwnerType {
  User = 'User',
  Organization = 'Organization',
}

export class Owner implements Identifiable {
  type: OwnerType;
  id: string;

  constructor(json: any, type: OwnerType) {
    this.type = type;
    this.id = json.id;
  }

  public static getFactory(): Factory<Owner> {
    return new (class implements Factory<Owner> {
      make(json: any): Owner {
        switch (json.type) {
          case OwnerType.User:
            return new UserOwner(json);
          case OwnerType.Organization:
            return new OrganizationOwner(json);
          default:
            throw new Error('Unrecognized Owner type (' + json.type + ').');
        }
      }

      getTableName(): string {
        throw new Error('Owners are never stored in IndexedDB.');
      }
    })();
  }
}

export class UserOwner extends Owner {
  constructor(json: any) {
    super(json, OwnerType.User);
  }
}

export class OrganizationOwner extends Owner {
  constructor(json: any) {
    super(json, OwnerType.Organization);
  }
}
