export class NewUserExtra {
  phoneVerificationCode: string;
  organizationId: string;
  mothershipId?: string;
  constructor(json: any) {
    this.phoneVerificationCode = json.phoneVerificationCode
      ? json.phoneVerificationCode
      : undefined;
    this.organizationId = json.organizationId
    this.mothershipId = json.mothershipId ? json.mothershipId : undefined;
  }
}
