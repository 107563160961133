import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CountryCode, Fault, FaultCode, I18nString, LanguageCode } from 'src/app/models';
import { CacheService } from 'src/app/services';
import { ErrorService } from 'src/app/shared/error/error.service';

@Component({
  selector: 'app-sjekkpunkt-form',
  templateUrl: './sjekkpunkt-form.component.html',
  styleUrls: ['./sjekkpunkt-form.component.scss'],
})
export class SjekkpunktFormComponent implements OnInit {
  form: FormGroup;
  inProgress: boolean = false;
  showSuccessMessage: boolean = false;

  CountryCode = CountryCode;
  countryCode = CountryCode.NO;

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private cache: CacheService,
    private errorService: ErrorService,
  ) {
    this.form = this.formBuilder.group({
      countrycode: [CountryCode.NO, [Validators.required]],
      registrationnumber: ['', [Validators.required]],
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      addressinsured: ['', [Validators.required]],
      zipinsured: ['', [Validators.required]],
      placeinsured: ['', [Validators.required]],
      phoneinsured: ['', [Validators.required]],
      policystart: ['', [Validators.required]],
      warrantyperiod: ['', [Validators.required]],
      carbrand: ['', [Validators.required]],
      carmodel: ['', [Validators.required]],
      businessname: ['', [Validators.required]],
      orgid: ['', [Validators.required]],
      passphrase: ['', [Validators.required]],
    });
  }

  async ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      // Patch values to the form with case-insensitive keys
      Object.keys(params).forEach((key) => {
        const lowercaseKey = key.toLowerCase();
        this.form.controls[lowercaseKey].patchValue(params[key]);

        // Format PolicyStart date
        const policyStartDate: string = lowercaseKey === 'policystart' ? params[key] : '';
        if (policyStartDate) {
          const formattedDate = this.formatDate(policyStartDate);

          this.form.patchValue({ policystart: formattedDate });
        }

        // formatWarrantyPeriodToNumber
        const warrantyPeriod: string = lowercaseKey === 'warrantyperiod' ? params[key] : '';
        if (warrantyPeriod) {
          const formattedWarrantyPeriod = parseInt(warrantyPeriod);

          this.form.patchValue({ warrantyperiod: formattedWarrantyPeriod });
        }
      });
    });
  }

  formatDate(dateString: string): string {
    const match = dateString.match(/^(\d{4})(\d{2})(\d{2})$/);

    if (match) {
      const year = match[1];
      const month = match[2];
      const day = match[3];

      return `${year}-${month}-${day}`;
    }

    // If the string doesn't match the expected format, return the original string
    return dateString;
  }

  async submit() {
    if (this.inProgress) {
      return;
    }

    this.inProgress = true;

    if (this.form.invalid) {
      this.form.markAllAsTouched();

      const invalidControls = Object.keys(this.form.controls).filter((controlName) => {
        const control = this.form.get(controlName);
        return control && control.invalid;
      });

      if (invalidControls.length === 1) {
        this.form.setErrors({
          failure: 'Dette feltet er ugyldig: ' + invalidControls[0],
        });
      } else {
        this.form.setErrors({
          failure: 'Disse feltene er ugyldige: ' + invalidControls.join(', '),
        });
      }

      this.inProgress = false;
      return;
    }

    const countryCodeNO = CountryCode.NO;

    const data = {
      passphrase: this.fv.passphrase.trim(),
      registrationNumber: this.fv.countrycode + this.fv.registrationnumber.trim(),
      firstName: this.fv.firstname.trim(),
      lastName: this.fv.lastname.trim(),
      addressInsured: this.fv.addressinsured.trim(),
      zipInsured: this.fv.zipinsured.trim(),
      placeInsured: this.fv.placeinsured.trim(),
      phoneInsured: this.fv.phoneinsured.trim(),
      policyStart: this.fv.policystart.trim(),
      warrantyPeriod: parseInt(this.fv.warrantyperiod),
      carBrand: this.fv.carbrand.trim(),
      carModel: this.fv.carmodel.trim(),
      businessName: this.fv.businessname.trim(),
      orgId: countryCodeNO + this.fv.orgid.trim(),
    };

    try {
      await this.cache.addSjekkpunktProduct(data);
      this.inProgress = false;
      this.showSuccessMessage = true;
      this.form.reset();
    } catch (e) {
      if (e instanceof Fault) {
        if (e.code === FaultCode.NotFound) {
          const noErrorMessage = new I18nString({
            language: LanguageCode.Norwegian,
            value:
              'Kunne ikke finne en produkt for verksted ' +
              this.fv.orgid.trim() +
              ', er denne organisasjonen registrert hos Viking?',
          });
          this.errorService.setError(noErrorMessage.value, e.code, 'Fant ikke verksted/produkt', ' ');
          this.inProgress = false;
        } else if (e.code === FaultCode.Unauthorized) {
          const noErrorMessage = new I18nString({
            language: LanguageCode.Norwegian,
            value: 'Du har ikke tilgang til å legge til produkter for verksted.',
          });

          this.errorService.setError(noErrorMessage.value, e.code, 'Ikke tilgang', ' ');
          this.inProgress = false;
        } else {
          this.errorService.setError(e.text, e.code);
          this.inProgress = false;
        }
      } else {
        this.errorService.setError('Ukjent feil');
        this.inProgress = false;
      }
    }
  }

  checkFormInputValidation(controlName: string): string {
    const control = this.form.get(controlName);
    if (!control) {
      return '';
    }

    if (control.invalid && control.dirty) {
      return 'border-font_error_bright';
    }

    if (control.invalid && control.touched) {
      return 'border-font_error_bright';
    }
    if (control.valid) {
      return 'border-font_success';
    }
    return '';
  }

  get fv() {
    return this.form.value;
  }
}
