import { Injectable } from '@angular/core';
import { RegionService } from '../region/region.service';
import { environment } from 'src/environments/environment';
import { CountryCode } from 'src/app/models';
import { HttpClient } from '@angular/common/http';

declare var gtag: Function;
declare var window: any;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  ga4ClientIdMap: Map<CountryCode, string> = new Map<CountryCode, string>();

  constructor(private regionService: RegionService) {
    this.ga4ClientIdMap.set(CountryCode.NO, 'G-NYMHYJ0S6K');
    this.ga4ClientIdMap.set(CountryCode.SE, 'G-J7J729GM9D');
  }

  public async sendTypedEvent(eventName: string, eventCategory: string, eventDetails?: string, eventType?: string) {
    this.sendEvent(
      eventName,
      {
        event_category: eventCategory,
        event_label: eventName,
        value: eventDetails,
      },
      eventType,
    );
  }

  public async sendEvent(eventName: string, eventParams: any, eventType?: string) {
    if (this.isEventTypeConsentGranted(eventType)) {
      return gtag('event', eventName, eventParams);
    } else {
      return false;
    }
  }

  public isEventTypeConsentGranted(eventType?: string): boolean {
    if (window.Cookiebot.consent[eventType!] && window.Cookiebot.consent[eventType!] == true) {
      return true;
    } else {
      return false;
    }
  }

  public getGA4ClientId(): string | undefined {
    if (environment.production) {
      return this.ga4ClientIdMap.get(this.regionService.getClientRegion());
    } else {
      return 'G-WWZ4LZ17LS';
    }
  }
}
