export class MothershipRequest {
  users: string; // Base64 string
  orders: string; // Base64 string
  orderRows: string; // Base64 string
  transactions: string; // Base64 string
  transactionRows: string; // Base64 string

  constructor(json: any) {
    this.users = json.users;
    this.orders = json.orders;
    this.orderRows = json.orderRows;
    this.transactions = json.transactions;
    this.transactionRows = json.transactionRows;
  }
}
