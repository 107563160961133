import { Deletable } from '../interface/deletable';
import { Identifiable } from '../interface/identifiable';
import { Factory } from '../interface/factory';
import { I18nString } from '../class/i18nstring';

export class VehicleBrand implements Deletable, Identifiable {
  id: string; // ALF
  deleted: boolean;
  deletedDbFlag: 0 | 1; // NOTE: Only used for indexing in browser DB.

  names: I18nString[]; // Alfa Romeo

  created: Date;
  modified: Date;

  constructor(json: any) {
    this.id = json.id;
    this.deleted = json.deleted ? Boolean(json.deleted) : false;
    this.deletedDbFlag = this.deleted ? 1 : 0;

    this.names = json.names ? json.names.map((json: any) => new I18nString(json)) : [];

    this.created = new Date(json.created);
    this.modified = new Date(json.modified);
  }

  public static getFactory(): Factory<VehicleBrand> {
    return new (class implements Factory<VehicleBrand> {
      make(json: any): VehicleBrand {
        return new VehicleBrand(json);
      }
      getTableName(): string {
        return 'vehicle_brands';
      }
    })();
  }

  static getUrl(brandId?: string): string;
  static getUrl(brandId: string): string {
    return '/vehicle_brands' + (brandId ? '/' + brandId : '');
  }
}
