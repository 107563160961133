import { I18nString } from './i18nstring';

export class ExtraRequestFile {
  data: string;
  descriptions: I18nString[];
  constructor(json: any) {
    this.data = json.data;
    this.descriptions = json.descriptions ? json.descriptions.map((js: any) => new I18nString(js)) : [];
  }
}

export class ExtraRequest {
  images: ExtraRequestFile[];
  constructor(json: any) {
    this.images = json.images ? json.images.map((js: any) => new ExtraRequestFile(js)) : [];
  }
}
