// [CountryCode.SE]: 'Sweden', // sv-SE
// [CountryCode.NO]: 'Norway', // no-NO
// [CountryCode.FI]: 'Finland', // fi-FI
// [CountryCode.DK]: 'Denmark', // da-DK

export class I18nString {
  language: string;
  value: string;
  constructor(json: any) {
    this.language = json.language;
    this.value = json.value;
  }
}
