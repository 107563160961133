import { Identifiable } from '../../interface/identifiable';
import { Deletable } from '../../interface/deletable';
import { HasUserId } from '../../interface/has-user-id';
import { Factory } from '../../interface/factory';
import { CountryCode } from '../../enum/country-code';
import { Period as ProductPeriod } from '../../enum/period';
import { HasTemplateId } from '../../interface/has-template-id';
import { Currency } from '../../enum/currency';
import { MothershipData } from './../mothership-data';
import { BeneficiaryType } from './beneficiary';
import { Owner } from '../owner';
import { PaymentSpecification } from '../payment-specification';

export class RenewalOffer {
  price: number; // Price including discount.
  constructor(json: any) {
    this.price = Number(json.price);
  }
}

export enum ProductStatus {
  Tentative = 'Tentative',
  Ongoing = 'Ongoing',
  Cancelled = 'Cancelled',
  Terminated = 'Terminated',
}

export class ProductParent implements Identifiable {
  id: string;
  start: Date;
  constructor(json: any) {
    this.id = json.id;
    this.start = new Date(json.start);
  }
}

export class Product implements Deletable, Identifiable, HasUserId, HasTemplateId {
  id: string;
  deleted?: boolean;
  deletedDbFlag: 0 | 1; // NOTE: Only used for indexing in browser DB.

  beneficiary: BeneficiaryType;
  // alias: string[]; TODO
  sequence: number; // Current sequence of product period. (Can be calculated from start time.)

  countryCode: CountryCode; // Denormalized for db queries.
  issuingOrganizationIds: string[]; // NOTE: Lower index is higher in the hierarchy. Denormalized from template.

  templateId: string;
  templateVersion: number; // NOTE: The version number is 1 + the index in the array of the template.

  policyholder: Owner;
  parent?: ProductParent;

  beneficiaryId: string; // Note: The beneficiary here is person, so this will be a user id.

  currency: Currency; // Denormalized from template.
  price: number; // Price before discount. NOTE: Sent from the client as after discount.
  netPremium: number;
  period: ProductPeriod;
  paymentSchedule: ProductPeriod;
  discountId?: string; // NOTE: Optional preferred discount for the next payment.

  status: ProductStatus;

  start: Date;
  qualifyingTime: Date;
  end?: Date; // Do not renew after this date.

  paymentSpecifications: PaymentSpecification[];

  renewalOffer?: RenewalOffer;

  mothershipData?: MothershipData;

  created: Date;
  modified: Date;

  constructor(json: any, beneficiary: BeneficiaryType) {
    this.id = json.id;
    this.deleted = json.deleted ? Boolean(json.deleted) : false;
    this.deletedDbFlag = json.deleted ? 1 : 0;

    this.beneficiary = beneficiary;

    // this.alias =  json.alias ?? []; 
    this.sequence = Number(json.sequence);

    this.countryCode = json.countryCode as CountryCode;
    this.issuingOrganizationIds = json.issuingOrganizationIds ?? [];

    this.templateId = json.templateId;
    this.templateVersion = Number(json.templateVersion);

    this.policyholder = Owner.getFactory().make(json.policyholder);
    this.parent = json.parent ? new ProductParent(json.parent) : undefined;

    this.beneficiaryId = json.beneficiaryId;

    this.currency = json.currency as Currency;
    this.price = Number(json.price);
    this.netPremium = Number(json.netPremium);
    this.period = json.period as ProductPeriod;
    this.paymentSchedule = json.paymentSchedule as ProductPeriod;
    this.discountId = json.discountId ? json.discountId : undefined;

    this.status = json.status as ProductStatus;

    this.start = new Date(json.start);
    this.qualifyingTime = new Date(json.qualifyingTime);
    this.end = json.end ? new Date(json.end) : undefined;

    const psf = PaymentSpecification.getFactory();
    this.paymentSpecifications = json.paymentSpecifications
      ? json.paymentSpecifications.map((p: any) => psf.make(p))
      : [];

    this.renewalOffer = json.renewalOffer ? new RenewalOffer(json.renewalOffer) : undefined;

    this.mothershipData = json.mothershipData ? new MothershipData(json.mothershipData) : undefined;

    this.created = json.created ? new Date(json.created) : new Date();
    this.modified = json.modified ? new Date(json.modified) : new Date();
  }

  canStatusChange(): boolean {
    if (this.status === ProductStatus.Ongoing) {
      return true;
    }

    // Status of Cancelled products can be changed if the end date has not passed.
    if (this.status === ProductStatus.Cancelled && this?.end !== undefined) {
      if (this.end) {
        const now = new Date().getTime();
        const end = this.end.getTime();
        return now < end;
      }
    }

    return false;
  }

  hasExpired(): boolean {
    const now = new Date().getTime();
    const end = this.end?.getTime() ?? 0;
    return now > end;
  }

  hasNotExpired(): boolean {
    const now = new Date().getTime();
    const end = this.end?.getTime() ?? 0;
    return now < end;
  }

  isActive(): boolean {
    return this.status === ProductStatus.Ongoing || (this.status === ProductStatus.Cancelled && this.hasNotExpired());
  }

  getLastPayment(): PaymentSpecification {
    return this.paymentSpecifications[this.paymentSpecifications.length - 1];
  }

  public static getFactory(): Factory<Product> {
    return new (class implements Factory<Product> {
      make(json: any): Product {
        switch (json.beneficiary) {
          case BeneficiaryType.User:
            return new UserProduct(json);
          case BeneficiaryType.Object:
            return new ObjectProduct(json);
          default:
            throw new Error('Unrecognized product beneficiary (' + json.beneficiary + ').');
        }
      }

      getTableName(): string {
        return 'products';
      }
    })();
  }

  static getUrl(productId?: string): string;
  static getUrl(productId: string): string {
    return '/products' + (productId ? '/' + productId : '');
  }
}

export class UserProduct extends Product {
  constructor(json: any) {
    super(json, BeneficiaryType.User);
  }
}

export class ObjectProduct extends Product {
  constructor(json: any) {
    super(json, BeneficiaryType.Object);
  }
}
