export enum FaultCode {
  Unspecified = 'Unspecified',
  Set = 'Set',
  ApiLevelNoLongerSupported = 'ApiLevelNoLongerSupported',
  Throttling = 'Throttling',
  Duplicate = 'Duplicate',
  WrongPassword = 'WrongPassword',
  NotFound = 'NotFound',
  Unauthorized = 'Unauthorized',
  Forbidden = 'Forbidden',
  IllegalArgument = 'IllegalArgument',
  IllegalState = 'IllegalState',
  Ineligible = 'Ineligible',
  NoData = 'NoData',
  NoExtra = 'NoExtra',
  Depleted = 'Depleted',
  PreconditionFailed = 'PreconditionFailed',
  FileError = 'FileError',
  Expired = 'Expired',
}
