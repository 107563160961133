export enum ObjectType {
  PB = 'PB', // Personbil, Car
  HB = 'HB', // Husbil, Camper
  LLB = 'LLB', // Lätt Lastbil, Light Truck
  TL = 'TL', // Tung Lastbil, Heavy Truck
  TB = 'TB', // Tung Buss, Heavy Bus
  HV = 'HV', // Husvagn, Caravan
  LSL = 'LSL', // Släpfordon, Light Trailer
  MC = 'MC', // Motorcykel, Motorbike
  MB = 'MB', // Mopedbil, Moped Car
  TSL = 'TSL', // Tungt släp, Heavy Trailer
}

export class Object {
  id: string;
  hide: boolean;
  model?: string;
  brandId: string;
  userId: string;
  type: ObjectType;
  regNr: string;
  modified: Date;
  constructor(json: any) {
    this.id = json.id;
    this.hide = json.hide;
    this.model = json?.model;
    this.brandId = json.brandId;
    this.userId = json.userId;
    this.type = json.type as ObjectType;
    this.regNr = json.regNr;
    this.modified = new Date(json.modified);
  }
}
