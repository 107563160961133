import { Identifiable } from '../../interface/identifiable';
import { Factory } from '../../interface/factory';

export enum BeneficiaryType {
  User = 'User',
  Object = 'Object',
}

export class Beneficiary implements Identifiable {
  type: BeneficiaryType;
  id: string;

  constructor(json: any, type: BeneficiaryType) {
    this.type = type;
    this.id = json.id;
  }

  public static getFactory(): Factory<Beneficiary> {
    return new (class implements Factory<Beneficiary> {
      make(json: any): Beneficiary {
        switch (json.type) {
          case BeneficiaryType.User:
            return new UserBeneficiary(json);
          case BeneficiaryType.Object:
            return new ObjectBeneficiary(json);
          default:
            throw new Error('Unrecognized Beneficiary type (' + json.type + ').');
        }
      }

      getTableName(): string {
        throw new Error('Beneficiaries are never stored in IndexedDB.');
      }
    })();
  }
}

export class UserBeneficiary extends Beneficiary {
  constructor(json: any) {
    super(json, BeneficiaryType.User);
  }
}

export class ObjectBeneficiary extends Beneficiary {
  constructor(json: any) {
    super(json, BeneficiaryType.Object);
  }
}
