<!-- Meny -->
<section
  class="grid items-center h-20 grid-cols-2 bg-background_error_bright xl:grid-cols-3"
>
  <div class="mr-4 justify-self-end tablet-sm:mr-8 xl:mr-12">
    <div class="flex justify-between gap-8"></div>
  </div>
</section>

<!-- Main content -->
<section
  class="laptop-sm:flex laptop-sm:flex-col laptop-sm:justify-center"
  style="min-height: calc(100vh - 80px)"
>
  <!-- Mobile and tablet logo -->
  <div class="flex flex-col items-center mt-4 laptop-sm:hidden">
    <img src="../../../assets/png/sjekkpunkt-logo.png" class="w-48" alt="" />
    <div class="flex flex-col">
      <p class="my-2 text-xl font-averta-semibold text-end">Powered by</p>
      <img
        src="../../../assets/svg/viking-logo-full-color.svg"
        class="w-48"
        alt=""
      />
    </div>
  </div>

  <!-- Form -->
  <form
    [formGroup]="form"
    class="flex flex-col justify-center p-8 mx-4 my-8 mobile-lg:mx-8 tablet-sm:mx-12 tablet-sm:px-8 tablet-sm:py-8 tablet-md:max-w-[650px] tablet-md:mx-auto rounded-2xl bg-background_pink_primary font-calibre-regular laptop-sm:max-w-840 laptop-sm:grid laptop-sm:grid-cols-2"
  >
    <div
      class="flex flex-col items-center justify-center col-start-2 row-span-4 ml-8 -mt-10 max-laptop-sm:hidden"
    >
      <img src="../../../assets/png/sjekkpunkt-logo.png" class="w-60" alt="" />
      <div class="flex flex-col">
        <p class="my-2 text-xl font-averta-semibold text-end">Powered by</p>
        <img
          src="../../../assets/svg/viking-logo-full-color.svg"
          class="w-60"
          alt=""
        />
      </div>
    </div>
    <div class="flex gap-4 laptop-sm:col-start-1 laptop-sm:row-start-1">
      <div class="flex flex-col w-[30%]">
        <label for="countrycode">Country</label>
        <select
          class="flex h-full mb-2 text-lg justify-self-center bg-background_white_primary text-font_black_primary"
          formControlName="countrycode"
        >
          <option value="{{ CountryCode.NO }}">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;N
          </option>
          <option value="{{ CountryCode.SE }}">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;S
          </option>
        </select>
      </div>
      <div class="w-full">
        <label for="registrationnumber">Registration number</label>
        <input
          type="text"
          id="registrationnumber"
          oninput="this.value = this.value.toUpperCase();"
          formControlName="registrationnumber"
          class="block w-full p-2 mb-2 border rounded-md border-background_secondary focus:outline-none"
          [ngClass]="checkFormInputValidation('registrationnumber')"
        />
      </div>
    </div>

    <div class="flex gap-4 laptop-sm:row-start-2">
      <div class="w-full">
        <label for="firstname">First name</label>
        <input
          type="text"
          id="firstname"
          formControlName="firstname"
          class="block w-full p-2 mb-2 border rounded-md border-background_secondary focus:outline-none"
          [ngClass]="checkFormInputValidation('firstname')"
        />
      </div>
      <div class="w-full">
        <label for="lastname">Last name</label>
        <input
          type="text"
          id="lastname"
          formControlName="lastname"
          class="block w-full p-2 mb-2 border rounded-md border-background_secondary focus:outline-none"
          [ngClass]="checkFormInputValidation('lastname')"
        />
      </div>
    </div>
    <label for="addressinsured">Address insured</label>
    <input
      type="text"
      id="addressinsured"
      formControlName="addressinsured"
      class="block w-full p-2 mb-2 border rounded-md border-background_secondary focus:outline-none"
      [ngClass]="checkFormInputValidation('addressinsured')"
    />

    <div class="flex gap-4">
      <div class="w-full">
        <label for="zipinsured">Zip code</label>
        <input
          type="text"
          id="zipinsured"
          oninput="this.value = this.value.replace(/[^0-9]/g, '');"
          formControlName="zipinsured"
          class="block w-full p-2 mb-2 border rounded-md border-background_secondary focus:outline-none"
          [ngClass]="checkFormInputValidation('zipinsured')"
        />
      </div>
      <div class="w-full">
        <label for="placeinsured">City</label>
        <input
          type="text"
          id="placeinsured"
          formControlName="placeinsured"
          class="block w-full p-2 mb-2 border rounded-md border-background_secondary focus:outline-none"
          [ngClass]="checkFormInputValidation('placeinsured')"
        />
      </div>
    </div>

    <div class="laptop-sm:ml-8 laptop-sm:col-start-2">
      <label for="phoneinsured">Phone number</label>
      <input
        type="text"
        id="phoneinsured"
        oninput="this.value = this.value.replace(/[^0-9+]/g, '');"
        formControlName="phoneinsured"
        class="block w-full p-2 mb-2 border rounded-md border-background_secondary focus:outline-none"
        [ngClass]="checkFormInputValidation('phoneinsured')"
      />
    </div>

    <div class="">
      <label for="policystart">Policy start date</label>
      <input
        type="text"
        id="policystart"
        formControlName="policystart"
        class="block w-full p-2 mb-2 border rounded-md border-background_secondary focus:outline-none"
        [ngClass]="checkFormInputValidation('policystart')"
      />
    </div>

    <div class="laptop-sm:ml-8">
      <label for="warrantyperiod">Warranty period</label>
      <input
        type="text"
        oninput="this.value = this.value.replace(/[^0-9]/g, '');"
        id="warrantyperiod"
        formControlName="warrantyperiod"
        class="block w-full p-2 mb-2 border rounded-md border-background_secondary focus:outline-none"
        [ngClass]="checkFormInputValidation('warrantyperiod')"
      />
    </div>

    <div class="flex gap-4">
      <div class="w-full">
        <label for="carbrand">Brand</label>
        <input
          type="text"
          id="carbrand"
          oninput="this.value = this.value.toUpperCase();"
          formControlName="carbrand"
          class="block w-full p-2 mb-2 border rounded-md border-background_secondary focus:outline-none"
          [ngClass]="checkFormInputValidation('carbrand')"
        />
      </div>

      <div class="w-full">
        <label for="carmodel">Model</label>
        <input
          type="text"
          id="carmodel"
          oninput="this.value = this.value.toUpperCase();"
          formControlName="carmodel"
          class="block w-full p-2 mb-2 border rounded-md border-background_secondary focus:outline-none"
          [ngClass]="checkFormInputValidation('carmodel')"
        />
      </div>
    </div>

    <div class="laptop-sm:ml-8">
      <label for="businessname">Business name</label>
      <input
        type="text"
        id="businessname"
        formControlName="businessname"
        class="block w-full p-2 mb-2 border rounded-md border-background_secondary focus:outline-none"
        [ngClass]="checkFormInputValidation('businessname')"
      />
    </div>
    <div>
      <label for="orgid">Organization id</label>
      <input
        type="text"
        id="orgid"
        formControlName="orgid"
        class="block w-full p-2 mb-2 border rounded-md border-background_secondary focus:outline-none"
        [ngClass]="checkFormInputValidation('orgid')"
      />
    </div>

    <div
      class="laptop-sm:ml-8 laptop-sm:place-items-end laptop-sm:flex laptop-sm:mb-2 max-laptop-sm:mt-4"
    >
      <button
        type="submit"
        class="w-full h-[43px] items-center flex justify-center p-1.5 mt-0.5 text-lg rounded-md cursor-pointer font-calibre-medium text-text_primary bg-background_error_bright text-font_white_primary focus:outline-none hover:bg-background_error"
        (click)="submit()"
      >
        <span
          class="inline-block text-font_white_primary font-averta-semibold"
          *ngIf="!inProgress"
          >Register</span
        >
        <span
          *ngIf="inProgress"
          class="text-font_white_primary font-averta-semibold"
          [ngClass]="{ 'custom-loader': inProgress }"
        ></span>
      </button>
    </div>
    <div
    *ngIf="form.errors && form.errors['failure']"
    class="mt-1 text-sm laptop-sm:ml-8 text-font_error font-averta-semibold laptop-sm:col-start-2 laptop-sm:flex laptop-sm:justify-start"
  >
    <p>{{form.errors['failure'] }}</p>
  </div>
    <div
      *ngIf="showSuccessMessage"
      class="text-lg font-averta-semibold laptop-sm:col-start-2 laptop-sm:flex laptop-sm:justify-center"
    >
      <p>Produktet er nå registrert!</p>
    </div>
  </form>
  <!-- Middle column with pink background -->
  <div></div>
  <!-- Empty right column -->
</section>
